import React, { useState, useEffect } from "react";
import qs from "query-string";
import { Link, useLocation } from "react-router-dom";
import { useForm, Controller, UseFormMethods } from "react-hook-form";

import { Input } from "@ui-kit/InputFields";
import Dialog from "@ui-kit/Dialog";
import { createCss } from "./styles";
import Button from "@ui-kit/Button";
import { ReactGAEventWait } from "@helpers/ga";
import { USER_API } from "@services/api";
import { useNotificationStore } from "@store/NotificationStore";
import { ServerError } from "@models/common";
import { ROUTES } from "@constants";
import { expectServerError } from "@helpers/serverError";

interface IProps {
  onClose: () => void;
}

type FormFields = {
  email: string;
};

type ViewVariant = "general" | "auth_required";

const instanceOfFormFields = (field: string) => {
  return field === "email";
};

const processErrors = (
  errors: ServerError[],
  setError: UseFormMethods["setError"],
) => {
  let errorHandled = false;

  errors.forEach((e) => {
    const paths = e.source.pointer?.split("/");

    if (!paths) {
      return false;
    }

    if (instanceOfFormFields(paths[1])) {
      setError(paths[1], {
        message: e.title,
        type: "manual",
      });
      errorHandled = true;
    }
  });

  return errorHandled;
};

const isForgotPasswordView = (view: string): view is ViewVariant => {
  return view === "general" || view === "auth_required";
};

const ForgotPasswordForm: React.FC<IProps> = ({ onClose }) => {
  const { search } = useLocation();
  const [view, setView] = useState<ViewVariant>("general");
  const [, { setUnknownErrorNotification, setNotification }] =
    useNotificationStore();
  const { handleSubmit, control, errors, formState, setError } =
    useForm<FormFields>();
  const [emailSent, setEmailSent] = useState<string | null>(null);
  const css = createCss();

  useEffect(() => {
    ReactGAEventWait({ category: "PasswordReset", action: "Opened" });

    const query = qs.parse(search);

    if (isForgotPasswordView(query.signUpVariant as string)) {
      setView(query.signUpVariant as ViewVariant);
    }
  }, []);

  function closeModal() {
    ReactGAEventWait({ category: "PasswordReset", action: "Closed" });
    onClose();
  }

  const submitForm = handleSubmit(async ({ email }) => {
    try {
      await USER_API.usersPasswordReset({
        usersPasswordReset: {
          email,
        },
      });

      ReactGAEventWait({ category: "PasswordReset", action: "LinkRequested" });

      setEmailSent(email);
    } catch (e) {
      const errors = expectServerError(e, setUnknownErrorNotification);

      if (!processErrors(errors, setError)) {
        setNotification({
          message: errors[0].title,
          type: "error",
        });
      }
    }
  });

  return (
    <Dialog
      open
      onClose={view !== "auth_required" ? closeModal : undefined}
      title="Forgot password"
      data-test="reset-password-dialog"
    >
      {!emailSent ? (
        <form
          data-test="forgot-password-form"
          css={css.container}
          onSubmit={submitForm}
        >
          <div>
            <p>Instructions will be sent to your account linked email</p>
            <Controller
              as={
                <Input
                  data-test="email-field"
                  error={errors.email?.message}
                  label="Enter email"
                  type="email"
                />
              }
              name="email"
              control={control}
              defaultValue=""
            />
          </div>
          <div css={css.footer}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              data-test="send-btn"
              loading={formState.isSubmitting}
              type="submit"
            >
              Send instructions
            </Button>
            <p css={css.footerLink}>
              Have an account?{" "}
              <Link
                to={{
                  pathname: ROUTES.LOGIN_FORM,
                  search,
                }}
                data-test="log-in-link"
              >
                Log in
              </Link>
            </p>
            <p css={css.footerLink}>
              Don't have an account?{" "}
              <Link
                to={{
                  pathname: ROUTES.SIGNUP_FORM,
                  search,
                }}
                data-test="sign-up-link"
              >
                Sign up
              </Link>
            </p>
          </div>
        </form>
      ) : (
        <div css={css.container}>
          <div css={css.confirmationContent} data-test="reset-instructure">
            To reset password:
            <ul>
              <li>1. Open email sent to {emailSent}</li>
              <li>2. Click email embedded link</li>
              <li>3. Reset password</li>
            </ul>
            Emailed link will be valid for 10 minutes
          </div>
          <div css={css.footer}>
            {view !== "auth_required" ? (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                data-test="done-btn"
                onClick={closeModal}
              >
                Done
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                data-test="returnToHomepage"
                link={{
                  pathname: "/",
                }}
              >
                Return to homepage
              </Button>
            )}
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default ForgotPasswordForm;
