import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  container: (theme) => ({
    alignContent: "space-between",
    display: "grid",
    position: "relative",
    width: "100%",

    [theme.mediaRequests.notMobile]: {
      width: 400,
    },
  }),

  footer: {
    marginTop: 30,
  },

  footerLink: {
    marginTop: 15,
    textAlign: "center",
  },

  confirmationContent: {
    "& ul": {
      lineHeight: "26px",
      listStyle: "none",
      margin: "15px 0",
    },
  },
});
