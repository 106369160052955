import { AxiosError } from "axios";
import isFunction from "lodash/isFunction";

import { ServerError } from "@models/common";

export const isServerError = (
  e: any,
): e is AxiosError<{ errors: ServerError[] }> => {
  if (!e.isAxiosError) {
    return false;
  }

  const errors = e.response?.data?.errors;

  return Boolean(Array.isArray(errors) && errors.length);
};

export const expectServerError = (e: any, onError?: () => void) => {
  if (!isServerError(e)) {
    if (isFunction(onError)) {
      onError();
    } else {
      throw e;
    }
  }

  return e.response?.data.errors as ServerError[];
};
